<template>
  <BannerStyle1 :list="bannerList" :position="4"></BannerStyle1>
  <div class="usually-box">
    <section class="introduce top-box">
      <h3 class="title">产品概述</h3>
      <p class="descript">自主可控区块链服务平台，提供全面的区块链即服务功能，从企业和开发者角度出发，提供多种部署形式，既能灵活部署，又安全易用，构建各种业务场景更安全稳定的区块链环境，简化运维及开发流程，实现业务快速上链。</p>
    </section>
    <section class="superiority">
      <h3 class="title">平台优势</h3>
      <ul class="flex-between">
        <li class="shadow-hover" v-for="(item, index) in superiorityList" :key="index">
          <img :src="item.img" alt="">
          <h3>{{item.name}}</h3>
          <p>{{item.desc}}</p>
        </li>
      </ul>
    </section>
    <section class="function-box usually-box">
      <h3 class="title">功能特性</h3>
      <SingleLine :list="funList"></SingleLine>
    </section>
    <section class="framework">
      <h3 class="title">产品架构</h3>
      <el-image :src="src" :preview-src-list="srcList" />
    </section>
  </div>
  <section class="place-box usually-box">
    <h3 class="title small-title stroke">支持多业务场景的数字化解决方案</h3>
    <div class="descript">布局“数字新基建”提供全行业、全场景、一站式数字化解决方案</div>
    <ResolveTemplate :showBtn="false" :list="businessList"></ResolveTemplate>
  </section>
</template>
<script setup>
import { ref, reactive, computed } from 'vue'
import SingleLine from '@/components/SingleLine'
import ResolveTemplate from '@/components/ResolveTemplate'
const bannerList = [
  {
    img: require("@/assets/product/baas/banner.png"),
    title: '海马链 BssS 平台',
    desc: '提供全面的区块链即服务功能，实现业务快速上链',
    btnText: '了解产品',
    url: '/post/contact'
  }
]

const superiorityList = [
  {
    name: '快速应用',
    img: require("@/assets/product/baas/goodness1.png"),
    desc: 'BaaS平台为企业提供一站式建链、用链、上链服务，提供便捷的管理工具和接入方式。',
  },
  {
    name: '简单易上手',
    img: require("@/assets/product/baas/goodness2.png"),
    desc: '无须深入学习区块链技术，即可快速搭建区块链系统，便捷地将业务系统和区块链系统对接，实现传统业务的区块链转型。',
  },
  {
    name: '跨链交易',
    img: require("@/assets/product/baas/goodness3.png"),
    desc: '通过身份链对企业证书透明管理，企业节点数据通过签名后完全可信，为数据交易和接口开放提供保障。',
  },
  {
    name: '赋能产业',
    img: require("@/assets/product/baas/goodness4.png"),
    desc: '助力企业应用区块链技术实现业务升级，赋能传统经济，建立去中心化可信应用生态，消除信任壁垒，打造快速增长引擎。',
  },
]

const funList = [
  {
    name: '创建联盟链',
    img: require('@/assets/product/baas/fun1.png'),
    desc: '用户可以在创建联盟链页面中，填写信息创建我的联盟链，创建成功以后返回联盟链管理'
  },
  {
    name: '联盟链管理',
    img: require('@/assets/product/baas/fun2.png'),
    desc: '联盟链由“我创建”和“我加入”组成。任何在内部的联盟都拥有邀请其他联盟的权限，当管理员审核通过以后，被邀请的联盟可进入联盟链中。审核失败的则不可以进入'
  },
  {
    name: '合约管理',
    img: require('@/assets/product/baas/fun3.png'),
    desc: '合约管理中可以自建合约管理，自建合约管理中拥有上传、发布、升级、审核安装的功能。 其他人也可对同一节点上自建的合约工程进行审核安装'
  },
  {
    name: '联盟机构管理',
    img: require('@/assets/product/baas/fun4.png'),
    desc: '联盟中任何角色均可邀请机构并由超级管理员审核，普通角色可以查询联盟机构详情，超级管理员可以查询展示联盟机构的详情，并对联盟机构进行管理'
  },
  {
    name: '数据导出',
    img: require('@/assets/product/baas/fun5.png'),
    desc: '主要是用于导出联盟链的的数据，通过块高开始时间、结束时间来控制。'
  },
]

const businessList = [
  {
    id: 1,
    name: "供应链金融",
    title: "供应链金融",
    desc: "链接企业资产端及金融机构资金端，降低小微企业融资成本，为金融机构提供更多投资场景，助力普惠金融。",
    img: require("@/assets/product/baas/apply1.png"),
  },
  {
    id: 2,
    name: "应收账款管理",
    title: "应收账款管理",
    desc: "未来的供应链将是无界供应链， 供应链上的各主体要在互信下完成多方交易和信息共享与互换，可以提升整条供应链的运转效率！海马链BaaS平台帮助企业建立基于区块链的供应链网络，将供应链上下游数据连接整合，利用这些数据，提升各主体协同运作，降本增效，基于此，供应链预测准确率将提升70%。",
    img: require("@/assets/product/baas/apply2.png"),
  },
  {
    id: 3,
    name: "健康医疗",
    title: "健康医疗",
    desc: "构建了由医疗机构，药房，配送机构，支付机构，监管机构做为参与方的协作联盟，并良好的解决了处方在多机构流转过程中的信息的隐私保护，防篡改、状态及时同步、全程可追溯，信息可审计等。",
    img: require("@/assets/product/baas/apply3.png"),
  },
  {
    id: 4,
    name: "政务领域",
    title: "政务领域",
    desc: "通过轻量化应用，数据拥有者随时随地，安全管理与授权数据资产，数据使用情况一目了然，消除数据被滥用和泄露的风险；数据链上流转，防篡改、可追溯，使用方业务权责清晰，数据所有权、使用权明确；监管部门可闭环评估数据资产使用状况，提升服务质量。",
    img: require("@/assets/product/baas/apply4.png"),
  },
  {
    id: 5,
    name: "电子可信合同",
    title: "电子可信合同",
    desc: "提供高效、司法可信、轻量便捷的电子合同存证解决方案，保证合同真实不可篡改、具备司法效力、提高维权效率。",
    img: require("@/assets/product/baas/apply5.png"),
  },
  {
    id: 6,
    name: "物联网",
    title: "物联网",
    desc: "有效地保障物联网设备采集数据的真实性，实现上链数据即为可信数据的目标。",
    img: require("@/assets/product/baas/apply6.png"),
  },
  {
    id: 7,
    name: "公益慈善",
    title: "公益慈善",
    desc: "在公益众筹场景下，为解决各环节不透明，难以监管等问题。海马链BaaS平台帮助在链上各机构组织相互背书，公开并共享信息，追溯每笔资金流向，保障公益机构的公信力，提升民众对公益众筹的信任度。",
    img: require("@/assets/product/baas/apply7.png"),
  },
  {
    id: 8,
    name: "可信存证",
    title: "可信存证",
    desc: "区块链的非对称加密、多方背书、不可篡改等特点使其符合数据存储的高安全性要求。区块链数字存证可以帮助企业记录关键信息并随时备查，防止信息泄露。同时，区块链数字存证还可以帮助企业有效降低纸质文档的成本，提升沟通效率。",
    img: require("@/assets/product/baas/apply8.png"),
  },
]

const src = require('@/assets/product/baas/frame.png');

const srcList = [
  require('@/assets/product/baas/frame.png')
]
</script>
<style lang='scss' scoped>
.title {
  padding: 94px 0 50px;
  font-size: 42px;
  color: #202020;
  text-align: center;
}
.introduce {
  position: relative;
  z-index: 10;
  padding: 0 60px 100px;
  border-radius: 6px;
  background: #fff;
  > p {
    font-size: 18px;
    line-height: 1.7em;
    color: #808080;
  }
}

.superiority {
  > ul {
    > li {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      box-sizing: border-box;
      padding: 35px 30px 60px;
      width: 285px;
      height: 360px;
      background-color: #fff;
      > h3 {
        margin: 29px 0 21px;
        width: 100%;
        font-size: 28px;
        font-weight: normal;
        text-align: center;
      }
      > img {
        height: 72px;
      }
      > p {
        font-size: 16px;
        line-height: 1.6em;
        color: #808080;
      }
    }
  }
}

.framework {
  > ul {
    flex-wrap: wrap;
    > li {
      position: relative;
      margin-bottom: 15px;
      width: 390px;
      height: 390px;
      background-color: #fff;
      &:hover {
        .coverd {
          display: flex;
        }
      }
      .back-img {
        width: 100%;
        height: 100%;
      }
      .coverd {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        display: none;
        justify-content: center;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
        color: #fff;
        background: rgba(0, 0, 0, 0.5);
        > img {
          height: 84px;
        }
        > h3,
        div {
          margin: 30px 0 27px;
          width: 100%;
          font-size: 28px;
          text-align: center;
        }
        > div {
          margin: 0;
          font-size: 20px;
        }
      }
    }
  }
}
.function-box {
  margin-bottom: 50px;
}
.place-box {
  padding-bottom: 70px;
  background: url("../../assets/home/resolve-back.png") no-repeat;
  background-size: cover;
  .small-title {
    text-align: center;
    color: #fff;
  }
  .descript {
    text-align: center;
    color: rgba(255, 255, 255, 0.8);
  }
}
</style>